import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

Vue.component('quotes', App)

new Vue({
    el: '#quotes'
})
