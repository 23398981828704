<template>
    <div>
        <div class="quotes__form">
            <codemirror type="text" v-model="form.content" :options="codeMirrorOptions" @focus="onCodeMirrorFocus" autocomplete="off" placeholder="What's on your mind?"></codemirror>
            <div style="text-align: right">
                <input type="text" :value="search" @input="updateSearch" autocomplete="off" placeholder="Search..."/>
                <button @click="add()">Post</button>
            </div>
            <div style="clear:both"></div>
        </div>

        <div id="quotes__container" class="quotes__wrapper">
            <div class="quotes__error" v-show="quota_error">Limit reached for public user. Please <a href="index2.php?action=user/login">login</a> to continue...</div>
            <div class="quotes__tabs" style="display:none" v-show="groups.length">
                <ul>
                    <li v-for="(group, i) in groups" :data-group="group" :id="'groups_' + group"><a :href="'#quotes__cat_' + i">{{ group }}</a></li>
                    <li @click="newGroup()"><span class="icon-add quotes__btn__new"></span></li>
                </ul>

                <div v-for="(group, i) in groups" :id="'quotes__cat_' + i">
                    <div class="quotes__items" v-show="filteredItems[group].length">
                        <QuoteItem
                            v-for="(item, index) in filteredItems[group]"
                            :key="item.id"
                            :item="item"
                            :group="group"
                            :index="index"
                            :editing="editing"
                            :groups="groups"
                            :updateContent="updateContent"
                            :remove="remove"
                            :toggleConfig="toggleConfig"
                            :moveTo="moveTo"
                            :codeMirrorOptions="codeMirrorOptions"
                        ></QuoteItem>
                        <infinite-loading @infinite="infiniteHandler">
                            <span slot="no-results"></span>
                        </infinite-loading>
                    </div>
                    <div v-else class="quotes__empty">This group has no quotes note</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import InfiniteLoading from 'vue-infinite-loading';
import QuoteItem from '@/components/QuoteItem';
import debounce from 'lodash.debounce';
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/markdown/markdown.js'
import 'codemirror/addon/display/placeholder.js'

export default {
    props: {
        uuid: { type: String, required: true },
        channel: { type: String, required: true }
    },
    components: {
        InfiniteLoading,
        QuoteItem,
        codemirror
    },
    data: function () {
        return {
            items: {},
            form: {
                content: '',
                group: ''
            },
            search: '',
            quota_error: false,
            editing: {}
        }
    },
    computed: {
        groups: function () {
            return Object.keys(this.filteredItems)
        },
        filteredItems: function () {
            if (this.search.length < 2) {
                return this.items
            }

            let filter = function (items, search) {
                return items.filter(item => {
                    return item.content.toLowerCase().includes(search.toLowerCase())
                })
            }

            let results = {}

            for (var group in this.items) {
                let matches = filter(this.items[group], this.search)

                if (matches.length) {
                    results[group] = matches
                }
            }

            return results
        },
        codeMirrorOptions: function() {
            return {
                mode: "markdown",
                theme: "none",
                lineWrapping: true,
                matchBrackets: true
            }
        }
    },
    watch: {
        filteredItems: {
            handler: function (newVal, oldVal) {
                let that = this

                this.$nextTick(function () {
                    that.onItemsChanged()
                })
            },
            deep: true
        }
    },
    created: function () {
        this.loadData()
    },
    mounted: function () {
        this.onMounted()
    },
    methods: {
        updateSearch: debounce(function (e) {
            this.search = e.target.value
        }, 300),
        loadData: function() {
            let that = this

            $.ajax({
                url: "index2.php?action=quotes/getItems",
                type: "GET",
                dataType: 'json',
                success: function(response) {
                    if (response.success) {
                        that.items = response.items
                    }
                }
            })
        },
        refresh: function() {
            this.loadData()
        },
        onItemsChanged: function() {
            let that = this

            $(function() {
                ensureTabs($(that.$el).find('.quotes__tabs'))

                that.ensureGroupsSortable()
            })
        },
        infiniteHandler: function($state) {
            let group = $(this.$el).find('.quotes__tabs li.ui-tabs-active').data('group')

            axios.get('index2.php?action=quotes/load_more', {
                params: {
                    skip: this.items[group].length,
                    group: group
                }
            }).then(({ data }) => {
                if (data.success && data.items[group] && data.items[group].length) {
                    this.items[group] = this.items[group].concat(data.items[group])

                    $state.loaded()
                } else {
                    $state.complete()
                }
            })
        },
        onMounted: function() {
            let that = this

            $(function() {
                $(that.$el).find('.quotes__form textarea').focus(function(){
                    $(that.$el).find('.quotes__form').addClass('quotes__form__focused')
                })

                $(document).click(function(event) {
                    if (!$(event.target).closest('.quotes__form .CodeMirror').length
                        && !$(event.target).is('.quotes__form button')) {
                        $(that.$el).find('.quotes__form').removeClass('quotes__form__focused')
                    }

                    if (!$(event.target).is('.quotes__item') && $(event.target).closest('.quotes__item').length == 0) {
                        console.log('xxxremoveconfig')
                        $(that.$el).find('.quotes__item').removeClass('config')
                    }
                })

                that.initWebSocket()
            })
        },
        onCodeMirrorFocus: function() {
            $(this.$el).find('.quotes__form').addClass('quotes__form__focused')
        },
        initWebSocket: function () {
            let sock = new ReconnectingWebSocket('wss://cloudpad9.com:9999/echo/websocket', null, {debug: false, reconnectInterval: 1000})

            this.sock = sock

            let that = this

            sock.onopen = function () {
                 sock.send(JSON.stringify({ channel: that.getWebSocketChannel(), command: 'subscribe' }))
            }

            sock.onmessage = function (event) {
                let message = JSON.parse(event.data)

                if (message.uuid && message.uuid != that.uuid) {
                    that.refresh()
                }
            }
        },
        notifyWebSocket: function() {
            if (this.sock) {
                this.sock.send(JSON.stringify({ channel: this.getWebSocketChannel(), uuid: this.uuid }))
            }
        },
        getWebSocketChannel: function() {
            return this.channel
        },
        toggleConfig: function (event) {
            let ele = event.currentTarget
console.log('xxxtoggleconfig')
            if (!getSelectionText()) {
                let item = $(ele).closest('.quotes__item')

                if (item.hasClass('editing')) {
                    return
                }

                this.editing = {}

                $(this.$el).find('.quotes__item').not(item).removeClass('config')

                item.toggleClass('config')
            }
        },
        ensureGroupsSortable: function () {
            var list = $(this.$el).find(".quotes__tabs >ul")

            list.sortable({
                stop: function (event, ui) {
                    var order = list.sortable('serialize', { expression: /(.+)_(.+)/ });

                    $.ajax({
                        type: "post",
                        url: "index2.php?action=quotes/update_groups_ordering",
                        data: order,
                        success: function(response) {
                            if (response.success) {
                            }
                        }
                    });
                }
            })
        },
        newGroup: function() {
            let that = this

            jPrompt('Enter title of a new group:', '', 'New group', function (value) {
                if (value) {
                    if (!that.items[value]) {
                        that.$set(that.items, value, [])
                    }
                }
            })
        },
        add: function() {
            let that = this
            let group = $(this.$el).find('.quotes__tabs li.ui-tabs-active').data('group')

            that.form.group = group

            let candidate = {content: that.form.content, group: group}

            let preAdd = function() {
                if (!that.items[group]) {
                    that.$set(that.items, group, [])
                }

                that.items[group].unshift(candidate)
                that.form.content = ''

                $(that.$el).find('.quotes__form').removeClass('quotes__form__focused')
            }

            preAdd()

            $.ajax({
                url: "index2.php?action=quotes/add",
                type: "POST",
                dataType: 'json',
                data: candidate,
                success: function(response) {
                    if (response.success) {
                        let item = response.item
                        let group = item.group

                        if (!that.items[group]) {
                            that.$set(that.items, group, [])
                        }

                        that.items[group][0] = item

                        that.notifyWebSocket()
                    } else {
                        if (response.quota_error) {
                            that.quota_error = true
                        }
                    }
                },
                error: function(request, status, error) {
                    that.form = that.items[group].shift()
                }
            })
        },
        updateContent: function(item, event) {
            let ele = event.currentTarget
            let that = this

            $.ajax({
                url: "index2.php?action=quotes/update_content&id=" + item.id + "&content=" + encodeURIComponent(this.editing[item.id]),
                type: "GET",
                dataType: 'json',
                success: function(response) {
                    if (response.success) {
                        item.content = response.content

                        that.editing[item.id] = false

                        that.notifyWebSocket()
                    }
                }
            })
        },
        remove: function(group, index, event) {
            let ele = event.currentTarget
            let that = this
            let item = this.items[group][index]

            ensureMessage($(ele), function() {
                $.ajax({
                    url: "index2.php?action=quotes/delete&id=" + item.id,
                    type: "GET",
                    dataType: 'json',
                    success: function(response) {
                        if (response.success) {
                            that.items[group].splice(index, 1);

                            // $('#quotes-container').find('li.quotes__page_break:last-child').remove()

                            // if ($('#quotes-container').find('li.quotes__item').length == 0) {
                            //     $('#quotes-container').find('li.quotes__load_more').remove()
                            // }

                            that.notifyWebSocket()
                        }
                    }
                })
            })
        },
        moveTo: function(group, targetGroup, index, event) {
            let ele = event.currentTarget
            let that = this
            let item = this.items[group][index]

            $.ajax({
                url: "index2.php?action=quotes/update_group&id=" + item.id + "&group=" + targetGroup,
                type: "POST",
                dataType: 'json',
                success: function(response) {
                    if (response.success) {
                        that.items[group].splice(index, 1)

                        that.items[targetGroup].push(item)

                        that.notifyWebSocket()
                    }
                }
            })
        }
    }
}
</script>

<style>
#tabs-quotes {
    padding: 0;
    background: #f3f3f2;
}
.quotes__wrapper {
    margin: 0;
    padding: 10px;
    margin-top: 5px;
    clear: both;
    position: relative;
    padding-bottom: 35px;
}
.ui-tabs.quotes__tabs .ui-tabs-panel {
    padding: 0;
}
.ui-tabs.quotes__tabs .ui-tabs-nav {
    background: #fff;
}
.ui-tabs.quotes__tabs .ui-tabs-nav .ui-tabs-anchor {
    text-transform: uppercase;
}
.ui-tabs.quotes__tabs .ui-tabs-nav .ui-tabs-active .ui-tabs-anchor {
    color: #37A000;
    font-weight: bold;
}
.quotes__items {
    padding: 0;
}
.quotes__item {
    position: relative;
}
.quotes__text {
    margin: 10px 0;
    padding: 5px 10px;
    border-radius: 2px;
    border: 1px solid #d1d5da;
    /* box-shadow: 2px 1px 8px 0px #dfdfdf; */
    line-height: 32px;
    font-size: 16px;
    /* font-family: Helvetica, Arial, sans-serif; */
    color: #333;
    background: #fff;
}
.quotes__text::before {
    display: none;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 40px;
    background: radial-gradient(#575450 6px, transparent 7px) repeat-y;
    background-size: 30px 30px;
    border-right: 3px solid #D44147;
    box-sizing: border-box;
}
.quotes__text:hover {
    /* box-shadow: 1px 1px 3px 0px #999fa1; */
}
.quotes__text .viewing pre {
    background: rgba(27,31,35,.05);
}
.quotes__text textarea {
    outline: none;
    border: none;
    width: 100%;
    height: 200px;
    overflow: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    resize: none;
    background: transparent;
}
.quotes__image {
    margin: 10px 0;
    margin-right: 25px;
    padding: 15px 10px;
    padding-left: 0;
    display: inline-block;
}
.quotes__image img {
    max-height: 300px;
    border: 1px solid #d1d5da;
    /* box-shadow: 2px 3px 8px 0px #607D8B; */
}
.quotes__image:hover img {
    /* box-shadow: 2px 3px 8px 0px #304651; */
}
.quotes__form {
    display: block;
    padding: 7px;
}
.quotes__form textarea {
    width: 100%;
    display: block;
    margin-bottom: 10px;
    border: 1px solid #d5d5d5;
    box-shadow: 2px 1px 8px 0px #dfdfdf;
    padding: 10px;
}
.quotes__form__focused textarea {
    min-height: 50vh;
}
.quotes__form .CodeMirror {
    margin-bottom: 10px;
    border: 1px solid #d5d5d5;
    box-shadow: 2px 1px 8px 0 #dfdfdf;
    padding: 10px;
    height: 80px;
}
.quotes__form__focused .CodeMirror {
    min-height: 50vh;
}
.quotes__form .CodeMirror-placeholder {
    color: #9E9E9E;
}
.quotes__form input {
    border: 0;
    outline: 0;
    margin-right: 10px;
    padding: 0 5px;
    border-bottom: 1px solid #dfe1e6;
    height: 25px;
}
.quotes__form button {
}
.quotes__editing {
    position: absolute;
    top: -12px;
    right: 165px;
    font-size: 20px;
    color: #9e9e9e;
    display: none;
}
.quotes__item.config .quotes__editing,
.quotes__item.editing .quotes__editing {
    display: block;
}
.quotes__editing span {
    margin-left: 5px;
}
.quotes__editing__save {
    display: none;
}
.quotes__item.editing .quotes__editing__edit {
    display: none;
}
.quotes__item.editing .quotes__editing__save {
    display: inline-block;
}
.quotes__delete {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 10px;
    color: red;
    font-size: 10px;
    cursor: pointer;
    display: none;
}
.quotes__delete:hover {
}
.quotes__image .quotes__delete {
    bottom: 5px;
    right: -20px;
}
.quotes__item.config .quotes__delete {
    display: block;
}
.quotes__load_more {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}
.quotes__load_more a {
    color: inherit;
}
.quotes__page_break {
    border-bottom: 2px dashed #607D8B;
    box-shadow: 2px 1px 8px 0px #353535;
}
.quotes__groups {
    position: absolute;
    top: -12px;
    right: 120px;
    font-size: 24px;
    color: #9E9E9E;
    cursor: pointer;
    display: none;
}
.quotes__item.config .quotes__groups {
    display: block;
}
.quotes__groups .dropdown-menu {
    border-radius: 2px;
    box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
    max-height: 90vh;
    overflow: auto;
    margin: -10px 0 0;
}
.quotes__groups:hover .dropdown-menu {
    display: block;
}
.quotes__groups .dropdown-menu .h {
    padding: 0 0 0 10px;
    font-size: 16px;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 8px;
}
.quotes__groups .dropdown-menu .dropdown-item {
    text-transform: uppercase;
}
.quotes__error {
    background: pink;
    padding: 5px;
    border: 1px solid #d5d5d5;
    box-shadow: 2px 1px 8px 0px #dfdfdf;
}
.quotes__error a {
    font-weight: bold;
    color: #000;
    text-decoration: underline;
}
.quotes__btn__new {
    display: inline-block;
    padding: 4px 10px 4px 10px;
    font-size: 20px;
}
.quotes__btn__new:hover {
    opacity: .8;
}
.quotes__empty {
    padding: 10px 0;
    font-style: italic;
}
.quotes__more::before {
    content: '...';
    color: #000;
    position: relative;
    top: -13px;
}
.quotes__more {
    cursor: pointer;
    background: #d9d9d9;
    border-radius: 6px;
    height: 10px;
    display: inline-block;
    position: relative;
    top: 11px;
    padding: 0 5px;
    width: 38px;
}
@media (max-width: 777px) {
    .quotes__form {
        padding: 0;
    }
    .ui-tabs.quotes__tabs .ui-tabs-nav {
        margin: 0;
    }
    .quotes__form input {
        width: 100px;
    }
}
</style>
