<template>
    <div :class="getItemClass(item)" :id="'item_' + item.id" :data-id="item.id" @click="toggleConfig($event)">
        <div>
            <img v-if="item.type == 'image'" :src="item.content"/>
            <div class="viewing" v-if="item.type != 'image' && !editing[item.id]" v-html="parseMarkdown(item.content)" v-more @dblclick.stop="setEditing(item)"></div>
            <codemirror v-model="editing[item.id]" v-if="editing[item.id]" :options="codeMirrorOptions"></codemirror>
            <span class="icon-cross quotes__delete" data-warning="Do you want to delete this item?" @click="remove(group, index, $event)"></span>
        </div>
        <div class="quotes__editing" v-show="item.type != 'image'">
            <span class="quotes__editing__edit icon-pencil" @click.stop="setEditing(item)"></span>
            <span class="quotes__editing__save icon-checkmark" @click.stop="updateContent(item, $event)"></span>
        </div>
        <div class="quotes__groups" v-show="groups.length > 1">
            <span class="icon-label" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></span>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <div class="h">Move to:</div>
                <input type="text" class="dropdown-item" placeholder="Search..." @keyup.stop="onDropdownFilter" @click.stop=""/>
                <a v-for="targetGroup in groups" class="dropdown-item" href="#" v-show="targetGroup != group" @click="moveTo(group, targetGroup, index, $event)">{{ targetGroup }}</a>
            </div>
        </div>
    </div>
</template>

<script>
import marked from 'marked'

import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/markdown/markdown.js'

export default {
    name: 'QuoteItem',
    props: {
        item: { type: Object, required: true },
        group: { type: String, required: true },
        index: { type: Number, required: true },
        editing: { type: Object, required: true },
        groups: { type: Array, required: true },
        updateContent: { type: Function, required: true },
        remove: { type: Function, required: true },
        toggleConfig: { type: Function, required: true },
        moveTo: { type: Function, required: true },
        codeMirrorOptions: { type: Object, required: true }
    },
    components: {
        codemirror
    },
    directives: {
        more: {
            inserted: function (el) {
                var showChar = 500
                var item = $(el)

                var content = item.html()

                if (content.length > showChar) {

                    var c = content.substr(0, showChar)
                    var h = content.substr(showChar, content.length - showChar)
                    var complete = c.slice(-4) === '</p>'

                    var html = c + ' <span class="quotes__more"></span>'

                    if (!complete) {
                        html += '</p>'
                    }

                    item.html(html)
                }

                item.find(".quotes__more").click(function(){
                    item.html(content)

                    return false
                })
            }
        }
    },
    methods: {
        parseMarkdown: function(content) {
            return marked(content)
        },
        getItemClass: function (item) {
            let cls = ['quotes__item', 'quotes__' + (item.type ? item.type : 'text')]

            if (this.editing[item.id]) {

                cls.push('editing')
            }

            return cls.join(' ')
        },
        setEditing: function(item) {
            this.$set(this.editing, item.id, item.content)
        },
        onDropdownFilter: function(event) {
            let input = $(event.target)
            let search = input.val().toLowerCase();

            input.closest('.dropdown-menu').find('a.dropdown-item').filter(function() {
                $(this).toggle($(this).text().toLowerCase().indexOf(search) > -1)
            })
        }
    }
}
</script>
